<template>
    <div class="dewha-container w-100">
    
      <div class="dewha-container1">
        <form @submit.prevent="goToResultPage()">
        <div class="dewha-card">
       
          <div class="mt-3">
            <!-- Question -->
            <div class="dewha-leadPage-heading mt-4 mt-md-3 mb-3 mt-md-0">
              {{ getHeading[0].content }}
            </div>
            <div v-if="getHeading[1]" class="dewha-question-heading mt-4 mb-3 mt-md-5">
              {{ getHeading[1].content }}
            </div>
            <!-- Options -->
            <div class="d-flex justify-content-center gap align-items-center flex-column mb-5 mt-4">
                <div class="" v-for="(control,index) in getInputControls" :key="index">
             <input  name="" v-model="control.selectedInfoType.fieldValue" :required="control.isRequired" :type="control.selectedInfoType.type" :placeholder="control.InfoTypePlaceholder" class="dewha-lead-email" id="">
            </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-center gap flex-wrap  my-5 pt-3 pb-5 py-md-5"
        >
          <div class="btn2">
            <button
              :disabled="isNextPageLoading"
              :style="{ pointerEvents: isNextPageLoading? 'none' : '', width:'245px' }"
              class="dewha-card-button d-flex align-items-center justify-content-between"
              type="submit"
            >
            Submit
              <div>
                <b-spinner
                  style="color: var(--dewha-primary)"
                  v-show="isNextPageLoading"
                  small
                ></b-spinner>
              </div>
              <span v-show="!isNextPageLoading" class="ml-2"
                ><i class="fa-solid fa-chevron-right"></i
              ></span>
            </button>
          </div>
         
        </div>
    </form>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import axios from "axios";

  
  export default {
    props: [
      "isTest",
      "isDesktopView",
      "animationType",
      "RoutePage",
      "getIsAnimationEnabled",
      "getAnimationSpeedinMillis",
      "apiToken",
    ],
    created() {
      this.pageDesign = this.getSinglePageValues();
    },
    data() {
      return {
        pageDesign: {},
        isNextPageLoading: null,
        selectedOption: {},
        email:''
      };
    },
    computed: {
      ...mapGetters([
        "getIsLoadingQuestion",
        "getSelectedPage",
        "getTotalPages",
        "getTotalPreviewPages",
        "getPreviewSettings",
        "getPreviewProgressBar",
        "getSavedLeadID",
        "translateText",
        "getEnvConst",
 
      ]),
   
    
  
      getHeading() {
        return this.getSelectedPage.pageDesign.blocksArray.filter(
          (block) => block.type == "text"
        );
      },
      getInputControls() {
        return this.getSelectedPage.pageDesign.blocksArray.filter(
          (block) => block.type == "freeText"
        );
      },
     
      findOptionsIndex() {
        const index = this.pageDesign.blocksArray.findIndex(
          (block) => block.type == "option"
        );
        return index;
      },
     
      isLoadingQuestion() {
        return this.getIsLoadingQuestion;
      },
      CurrentPage() {
        return this.getSelectedPage;
      },
      getLeadObjfromVuex() {
      window.quizell.leadData = this.getSavedLeadID;
      return this.getSavedLeadID;
    },
    },
    methods: {
      ...mapActions([
        "goToNextPreviewPage",
        "goToBackPreviewPage",
      ]),
      ...mapMutations([
        "backPageAnimation",
        "setSavedLeadID"
   
      ]),
      
      backPage() {
        this.backPageAnimation();
        this.goToBackPreviewPage();
      },
      getSinglePageValues() {
        let getStartedDesignValues = {};
        if (this.getSelectedPage) {
          getStartedDesignValues = this.getSelectedPage.pageDesign;
        }
        return getStartedDesignValues;
      },

    async  goToResultPage(){
        this.isNextPageLoading=true;
        const  leadInfo =this.getLeadObjfromVuex;
        this.getInputControls.forEach(inputControl => {
            leadInfo[inputControl.selectedInfoType.fieldName] = inputControl.selectedInfoType.fieldValue
        });
        
        let data = {
              quizId: this.getPreviewSettings.quizId,
              leadData: JSON.stringify(leadInfo),
              api_token: this.apiToken,
              quiz_analytic_id: this.getLeadObjfromVuex.quiz_analytic_id,
            };
           
            let { quiz_analytic_id } = this.getSavedLeadID;

            if (quiz_analytic_id) {
              try {
                const response = await axios.post(`/api/saveFinalLead`, data);
                if (response.status == 200) {

                  this.setSavedLeadID(response.data.data);
            
                    this.goToNextPreviewPage(this.getSelectedPage.value);
                 
                  
                }
            }
            catch(e){
                e
            }
            finally{
                this.isNextPageLoading=false;
            }
            

      }
    }
    },
    mounted(){
      this.selectedOption=this.getSelectedOption||{}
    }
  };
  </script>
  
  <style>
  @import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Amiko:wght@400;600;700&display=swap");
  .dewha-logo-section {
    box-shadow: 0px 4px 6.8px rgba(0, 0, 0, 0.25);
    background: white;
    border-radius: 100px;
    margin: 0px 20px 0px 20px;
  }
  .dewha-container {
    background-image: url(https://images.quizell.com/gallery/2951727157199.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
    /* height: 100vh; */
  }
  .dewha-container1 {
    padding: 0px 20px 0px 20px;
    --dewha-primary: #f14257;
    --dewha-secondary: #393939;
  }
  .dewha-logo {
    /* width: 200px; */
    height: 50px;
    margin-top: 17px;
    margin-bottom: 17px;
  }
  .dewha-card {
    margin-top: 30px;
    border-radius: 70px;
    background-image: url(https://images.quizell.com/gallery/2221727182465.png);
    background-position: center;
  
    background-repeat: no-repeat;
    box-shadow: 10px 30px 50px rgba(0, 0, 0, 0.3);
    padding: 21px;
    position: relative;
  }
  .dewha-card::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    bottom: -16px;
    background-image: url(https://images.quizell.com/gallery/2221727182465.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 70px;
    z-index: -1;
    box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.3);
  }
  .dewha-card::after {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    bottom: -31px;
    background-image: url(https://images.quizell.com/gallery/2221727182465.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 70px;
    z-index: -2;
  }
  .dewha-progress-bar {
    border-radius: 75px;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
  }
  .dewha-active-bar {
    border-radius: 75px;
    background-color: var(--dewha-primary);
    height: 11px;
  }
  .dewha-progressbar-counter {
    color: var(--dewha-secondary);
    font-family: "Work Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
  }
  .dewha-question-heading {
    color: #393939;
    text-align: center;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 117.218%; /* 32.821px */
    letter-spacing: 1.4px;
  }
  .dewha-option {
    border-radius: 162px;
    background: #fff;
    color: #393939;
    font-family: "Work Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 117.218%; /* 23.444px */
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 19px;
  }
  .dewha-options {
    gap: 30px;
  }
  .dewha-card-button:disabled {
    background-color: #fc8291;
  }
  .dewha-card-button {
    border-radius: 81.943px;
    background: var(--dewha-primary);
    color: #fff;
    text-align: center;
    font-family: "Amiko";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.35px;
    border: none;
    outline: none;
    padding: 16px 21px;
  }
  .dewha-card-button span {
    padding: 11px;
    background: #fff;
    border-radius: 50%;
    color: var(--dewha-primary);
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn1{
    order:2;
  }
  .btn2{
  order:1;
  }

  @media (min-width: 1024px) {
    .dewha-started {
      font-size: 80px;
      text-align: left;
    }
    .dewha-card {
      padding: 46px 32px;
    }
    .dewha-container1 {
      padding: 0px 220px 0px 220px;
    }
    .dewha-card-button {
      font-size: 28px;
    }
    .dewha-card-image {
      width: 480px;
    }
  }
  .dewha-leadPage-heading{
    font-size: 33px;
    font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -1.5px;
text-align: center;
color: #393939;
font-family: "Work Sans";
  }
  .dewha-lead-email{
    border-radius: 100px;
background: #FFF;
font-family: "Work Sans";
padding: 16px 20px;
   border: none;
   outline: none;
    width: 100%;
  }
  @media (min-width: 768px) {
    .btn1{
    order:1;
  }
  .btn2{
  order:2;
  }
  .dewha-lead-email{
    font-size: 22px;
    width: 580px;
    padding: 24px 30px;
  }
  .dewha-leadPage-heading{
    
font-size: 50px;

  }
    .dewha-logo {
      width: 138px;
      height: 60px;
      margin-top: 17px;
      margin-bottom: 17px;
    }
    .dewha-logo-section {
      border-radius: 0px;
      width: 100%;
      margin: 0px;
    }
    .dewha-active-bar {
      height: 19px;
    }
    .dewha-progressbar-counter {
      font-size: 18px;
    }
    .dewha-card {
      background-size: 107%;
    }
    .dewha-question-heading {
      font-size: 22px;
    }
    .dewha-options {
      gap: 0px;
    }
    .dewha-options .col-md-6 {
      margin-top: 10px;
    }
    .dewha-option {
      padding: 21px 19px;
      font-size: 20px;
    }
    .dewha-card::after {
      background-size: 100%;
    }
    .dewha-card-button span {
      font-size: 20px;
      width: 30px;
      height: 30px;
    }
  }
  @media (min-width: 1200px) {
    .dewha-card {
      padding: 46px 32px;
    }
  }
  
  /* Hide the default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border-radius: 50%;
    border: 5px solid #e95087;
    margin: 0px;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 1.3em;
    width: 1.3em;
    transition: 0.3s;
    transform: scale(0);
    border-radius: 50%;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #e95087;
    transform: scale(1.2);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkbox-container input:checked ~ .celebrate {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 0.45em;
    top: 0.25em;
    width: 0.3em;
    height: 0.5em;
    border: solid white;
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
  }
  
  .checkbox-container .celebrate {
    position: absolute;
    transform-origin: center;
    animation: kfr-celebrate 0.4s;
    animation-fill-mode: forwards;
    display: none;
    stroke: #e95087;
  }
  
  @keyframes kfr-celebrate {
    0% {
      transform: scale(0);
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      transform: scale(1.2);
      opacity: 0;
      display: none;
    }
  }
  </style>
  